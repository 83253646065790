export const journals = [
    {
        "id": 6,
        "title": "Journal of ufology",
        "issn": "2516-8290",
        "publisher": "Microbiology Society"
    },
    {
        "id": 4,
        "title": "Questions of oncology",
        "issn": "1466-5034",
        "publisher": "Microbiology Society"
    },
    {
        "id": 7,
        "title": "Astronavigation discussions",
        "issn": "2053-3721",
        "publisher": "Microbiology Society"
    },
    {
        "id": 2,
        "title": "Biology discoveries",
        "issn": "1465-2099",
        "publisher": "Microbiology Society"
    },
    {
        "id": 3,
        "title": "Random letters",
        "issn": "1473-5644",
        "publisher": "Microbiology Society"
    }
]