
export const articleDetails = {
    "article": {
        "id": 390,
        "meta": {
            "prioragreement": true,
            "source": "JATS",
            "status": "PUBLICATION"
        },
        "authors": [
            {
                "firstName": "Sharona",
                "lastName": "Montes",
                "initials": "LCW",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 1,
                "creditrole": "",
                "isCorrespondingAuthor": true,
                "institutions": [
                    {
                        "name": "University of Oxford",
                        "rorid": "https://ror.org/052gg0110",
                        "status": "enriched not confirmed"
                    },
                    {
                        "name": "Nekton"
                    }
                ],
                "affiliation": "University of Oxford,Nekton"
            },
            {
                "firstName": "Sherlock",
                "lastName": "Paolillo",
                "initials": "ST",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 2,
                "creditrole": "",
                "isCorrespondingAuthor": true,
                "institutions": [
                    {
                        "name": "Nekton",
                        "rorid": "http://orchid.org/4809434--23-2323",
                        "status": "enriched confirmed"
                    },
                    {
                        "name": "Ministry of Agriculture, Climate Change and Environment",
                        "rorid": "http://orchid.org/4809434--23-2323",
                        "status": "enriched not confirmed"
                    }
                ],
                "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
            },
            {
                "firstName": "Nikolaus",
                "lastName": "Clarke",
                "initials": "OS",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 3,
                "creditrole": "",
                "isCorrespondingAuthor": false,
                "institutions": [
                    {
                        "name": "Nekton"
                    }
                ],
                "affiliation": "Nekton"
            },
            {
                "firstName": "Adoree",
                "lastName": "Berrisford",
                "initials": "PS",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 4,
                "creditrole": "",
                "isCorrespondingAuthor": true,
                "institutions": [
                    {
                        "name": "University of Oxford",
                        "rorid": "https://ror.org/052gg0110",
                        "status": "enriched not confirmed"
                    },
                ],
                "affiliation": "University of Oxford,Nekton"
            },
            {
                "firstName": "Osgood",
                "lastName": "Millhouse",
                "initials": "MJ",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 5,
                "creditrole": "",
                "isCorrespondingAuthor": false,
                "institutions": [
                    {
                        "name": "Leiden University",
                        "rorid": "https://ror.org/027bh9e22",
                        "status": "enriched confirmed"
                    }
                ],
                "affiliation": "Leiden University"
            },
            {
                "firstName": "Dalia",
                "lastName": "Bushen",
                "initials": "AdC",
                "ORCID": "http://orchid.org/4809434--23-2323",
                "email": "JonDoe@gmail.com",
                "collaboration": "",
                "listingorder": 6,
                "creditrole": "",
                "isCorrespondingAuthor": false,
                "institutions": [
                    {
                        "name": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "affiliation": "Ministry of Agriculture, Climate Change and Environment"
            }
        ],
        "article": {
            "title": "Research in Pandemic decision-making is difficult and exhausting – here’s the psychology that explains why",
            "grants": [],
            "manuscript": {
                "dates": {
                    "submission": "2020-09-28",
                    "acceptance": "2021-03-15",
                    "publication": "2021-03-31"
                },
                "id": "568-8723232ase"
            },
            "preprint": {},
            "vor": {
                "publication": "open access / hybrid journal",
                "license": "CC BY",
                "deposition": "open repository, like PMC",
                "researchdata": "other"
            },
            "type": "review-article",
            "funders": [
                {
                    "name": "Island Development Company, Seychelles"
                },
                {
                    "name": "Seychelles Island Foundation"
                }
            ],
            "submissionId": "2323223",
            "doi": "10.1098/rsbl.1943.0699",
            "doiurl": ""
        },
        "journal": {
            "name": "Oxford university",
            "id": "568-871744-957X",
            "inDOAJ": false
        },
        "charges": {
            "prioragreement": true,
            "agreement": {
                "name": "BC_RES_87_076",
                "id": "568-87BC_RES_Joren_2021_0010"
            }
        },
        "created": "2021-08-26T21:01:01.301Z"
    },
    "events": [
        {
            "id": 6341,
            "state": "",
            "type": "In peer review",
            "created": "2021-08-26T21:01:01.296Z",
            "data": {
                "authors": [
                    {
                        "lastName": "John",
                        "firstName": "Doe",
                        "initials": "LCW",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 1,
                        "creditrole": "",
                        "isCorrespondingAuthor": true,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            }
                        ],
                        "affiliation": "University of Oxford"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "AdC",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 6,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "article": {
                    "title": "Why do cats’ eyes glow in the dark?",
                    "grants": [],
                    "manuscript": {
                        "dates": {
                            "submission": "2020-09-28",
                            "acceptance": "2021-03-15",
                            "publication": "2021-03-31"
                        },
                        "id": "568-87rsbl20200699"
                    },
                    "preprint": {},
                    "vor": {
                        "publication": "open access / hybrid journal",
                        "license": "CC BY",
                        "deposition": "open repository, like PMC",
                        "researchdata": "other"
                    },
                    "type": "review-article",
                    "funders": [
                        {
                            "name": "Seychelles Conservation and Climate Adaptation Trust"
                        },
                        {
                            "name": "Nekton, UK"
                        },
                        {
                            "name": "Ministry of Environment, Energy and Climate Change, Seychelles"
                        },
                        {
                            "name": "Island Development Company, Seychelles"
                        },
                        {
                            "name": "Seychelles Island Foundation"
                        }
                    ],
                    "submissionId": "rsbl20200699",
                    "doi": "10.1098/rsbl.1943.0699",
                    "doiurl": "†Joint first authors."
                },
                "journal": {
                    "name": "Biology Letters",
                    "id": "568-871744-957X",
                    "inDOAJ": false
                }
            }
        },
        {
            "id": 6342,
            "state": "",
            "type": "Accepted",
            "created": "2021-06-26T21:01:01.296Z",
            "data": {
                "authors": [
                    {
                        "lastName": "John",
                        "firstName": "Doe",
                        "initials": "LCW",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 1,
                        "creditrole": "",
                        "isCorrespondingAuthor": true,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "firstName": "Alie",
                        "lastName": "Imlin",
                        "initials": "ST",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 2,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "firstName": "Corrie",
                        "lastName": "Redparth",
                        "initials": "OS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 3,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "Nekton"
                    },
                    {
                        "firstName": "Neille",
                        "lastName": "Hanwright",
                        "initials": "PS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 4,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            },
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "MJ",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 5,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "AdC",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 6,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "article": {
                    "title": "Why do cats’ eyes glow in the dark?",
                    "grants": [],
                    "manuscript": {
                        "dates": {
                            "submission": "2020-09-28",
                            "acceptance": "2021-03-15",
                            "publication": "2021-03-31"
                        },
                        "id": "568-87rsbl20200699"
                    },
                    "preprint": {},
                    "vor": {
                        "publication": "open access / hybrid journal",
                        "license": "CC BY",
                        "deposition": "open repository, like PMC",
                        "researchdata": "other"
                    },
                    "type": "review-article",
                    "funders": [
                        {
                            "name": "Seychelles Conservation and Climate Adaptation Trust"
                        },
                        {
                            "name": "Nekton, UK"
                        },
                        {
                            "name": "Ministry of Environment, Energy and Climate Change, Seychelles"
                        },
                        {
                            "name": "Island Development Company, Seychelles"
                        },
                        {
                            "name": "Seychelles Island Foundation"
                        }
                    ],
                    "submissionId": "rsbl20200699",
                    "doi": "10.1098/rsbl.1943.0699",
                    "doiurl": "†Joint first authors."
                },
                "journal": {
                    "name": "Biology Letters",
                    "id": "568-871744-957X",
                    "inDOAJ": false
                },
                "charges": {
                    "prioragreement": true,
                    "agreement": {
                        "name": "BC_RES_87_076",
                        "id": "568-87BC_RES_Joren_2021_0010"
                    }
                },
                "settlement": {
                    "charges": {}
                }
            }
        },
        {
            "id": 6343,
            "state": "Completed",
            "type": "Transaction",
            "created": "2021-07-26T21:01:01.296Z",
            "data": {
                "authors": [
                    {
                        "lastName": "John",
                        "firstName": "Doe",
                        "initials": "LCW",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 1,
                        "creditrole": "",
                        "isCorrespondingAuthor": true,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "firstName": "Alie",
                        "lastName": "Imlin",
                        "initials": "ST",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 2,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "firstName": "Corrie",
                        "lastName": "Redparth",
                        "initials": "OS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 3,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "Nekton"
                    },
                    {
                        "firstName": "Neille",
                        "lastName": "Hanwright",
                        "initials": "PS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 4,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            },
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "MJ",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 5,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "AdC",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 6,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "article": {
                    "title": "Why do cats’ eyes glow in the dark?",
                    "grants": [],
                    "manuscript": {
                        "dates": {
                            "submission": "2020-09-28",
                            "acceptance": "2021-03-15",
                            "publication": "2021-03-31"
                        },
                        "id": "568-87rsbl20200699"
                    },
                    "preprint": {},
                    "vor": {
                        "publication": "open access / hybrid journal",
                        "license": "CC BY",
                        "deposition": "open repository, like PMC",
                        "researchdata": "other"
                    },
                    "type": "review-article",
                    "funders": [
                        {
                            "name": "Seychelles Conservation and Climate Adaptation Trust"
                        },
                        {
                            "name": "Nekton, UK"
                        },
                        {
                            "name": "Ministry of Environment, Energy and Climate Change, Seychelles"
                        },
                        {
                            "name": "Island Development Company, Seychelles"
                        },
                        {
                            "name": "Seychelles Island Foundation"
                        }
                    ],
                    "submissionId": "rsbl20200699",
                    "doi": "10.1098/rsbl.1943.0699",
                    "doiurl": "†Joint first authors."
                },
                "journal": {
                    "name": "Biology Letters",
                    "id": "568-871744-957X",
                    "inDOAJ": false
                },
                "charges": {
                    "prioragreement": true,
                    "agreement": {
                        "name": "BC_RES_87_076",
                        "id": "568-87BC_RES_Joren_2021_0010"
                    }
                },
                "settlement": {
                    "charges": {}
                }
            }
        },
        {
            "id": 6344,
            "type": "Published",
            "state": "",
            "created": "2021-08-26T21:01:01.296Z",
            "data": {
                "authors": [
                    {
                        "lastName": "John",
                        "firstName": "Doe",
                        "initials": "LCW",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 1,
                        "creditrole": "",
                        "isCorrespondingAuthor": true,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "firstName": "Alie",
                        "lastName": "Imlin",
                        "initials": "ST",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 2,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "firstName": "Corrie",
                        "lastName": "Redparth",
                        "initials": "OS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 3,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "Nekton"
                    },
                    {
                        "firstName": "Neille",
                        "lastName": "Hanwright",
                        "initials": "PS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 4,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "University of Oxford",
                                "rorid": "https://ror.org/052gg0110"
                            },
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "MJ",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 5,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "lastName": "Jon",
                        "firstName": "Doe",
                        "initials": "AdC",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 6,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "article": {
                    "title": "Why do cats’ eyes glow in the dark?",
                    "grants": [],
                    "manuscript": {
                        "dates": {
                            "submission": "2020-09-28",
                            "acceptance": "2021-03-15",
                            "publication": "2021-03-31"
                        },
                        "id": "568-87rsbl20200699"
                    },
                    "preprint": {},
                    "vor": {
                        "publication": "open access / hybrid journal",
                        "license": "CC BY",
                        "deposition": "open repository, like PMC",
                        "researchdata": "other"
                    },
                    "type": "review-article",
                    "funders": [
                        {
                            "name": "Seychelles Conservation and Climate Adaptation Trust"
                        },
                        {
                            "name": "Nekton, UK"
                        },
                        {
                            "name": "Ministry of Environment, Energy and Climate Change, Seychelles"
                        },
                        {
                            "name": "Island Development Company, Seychelles"
                        },
                        {
                            "name": "Seychelles Island Foundation"
                        }
                    ],
                    "submissionId": "rsbl20200699",
                    "doi": "10.1098/rsbl.1943.0699",
                    "doiurl": "†Joint first authors."
                },
                "journal": {
                    "name": "Biology Letters",
                    "id": "568-871744-957X",
                    "inDOAJ": false
                },
                "charges": {
                    "prioragreement": true,
                    "agreement": {
                        "name": "BC_RES_87_076",
                        "id": "568-87BC_RES_Joren_2021_0010"
                    }
                },
                "settlement": {
                    "charges": {}
                }
            }
        },
    ],
    "messages": [
        {
            "id": 6344,
            "type": "p1",
            "validity": "2022-08-26",
            "createdby": "Private datastore message handler",
            "to": {
                "address": "https://ror.org/052gg0110",
                "name": "University of Oxford"
            },
            "data": {
                "authors": [
                    {
                        "firstName": "Holmes",
                        "lastName": "Cornfoot",
                        "initials": "LCW",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 1,
                        "creditrole": "",
                        "isCorrespondingAuthor": true,
                        "institutions": [
                            {
                                "name": "University of Oxford"
                            },
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "firstName": "Evey",
                        "lastName": "Cotgrave",
                        "initials": "ST",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 2,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Nekton"
                            },
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Nekton,Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "firstName": "Dorothee",
                        "lastName": "Graeber",
                        "initials": "OS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 3,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "Nekton"
                    },
                    {
                        "firstName": "Kendricks",
                        "lastName": "Kerswill",
                        "initials": "PS",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 4,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "University of Oxford"
                            },
                            {
                                "name": "Nekton"
                            }
                        ],
                        "affiliation": "University of Oxford,Nekton"
                    },
                    {
                        "firstName": "Renell",
                        "lastName": "Cartwight",
                        "initials": "MJ",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 5,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    },
                    {
                        "firstName": "Carroll",
                        "lastName": "Spandley",
                        "initials": "AdC",
                        "ORCID": "http://orchid.org/4809434--23-2323",
                        "email": "JonDoe@gmail.com",
                        "collaboration": "",
                        "listingorder": 6,
                        "creditrole": "",
                        "isCorrespondingAuthor": false,
                        "institutions": [
                            {
                                "name": "Ministry of Agriculture, Climate Change and Environment"
                            }
                        ],
                        "affiliation": "Ministry of Agriculture, Climate Change and Environment"
                    }
                ],
                "article": {
                    "title": "Discovering new drugs is a long and expensive process – chemical compounds that dupe screening tools make it even harder",
                    "grants": [],
                    "manuscript": {
                        "dates": {
                            "submission": "2020-09-28",
                            "acceptance": "2021-03-15",
                            "publication": "2021-03-31"
                        },
                        "id": "568-87rsbl20200699"
                    },
                    "preprint": {},
                    "vor": {
                        "publication": "open access / hybrid journal",
                        "license": "CC BY",
                        "deposition": "open repository, like PMC",
                        "researchdata": "other"
                    },
                    "type": "review-article",
                    "funders": [
                        {
                            "name": "Seychelles Conservation and Climate Adaptation Trust"
                        }
                    ],
                    "submissionId": "rsbl20200699",
                    "doi": "10.1098/rsbl.1943.0699",
                    "doiurl": "†Joint first authors."
                },
                "journal": {
                    "name": "Biology Letters",
                    "id": "568-871744-957X",
                    "inDOAJ": false
                },
                "charges": {
                    "prioragreement": true,
                    "agreement": {
                        "name": "BC_RES_87_076",
                        "id": "568-87BC_RES_Joren_2021_0010"
                    },
                    "fees": {
                        "apc": {
                            "type": "per article APC list price",
                            "name": "firm",
                            "amount": 1700
                        },
                        "total": {
                            "name": "firm",
                            "amount": 1700
                        }
                    },
                    "source": "PA CHARGES"
                }
            }
        }
    ]
}