
export const inPipeLineArticlesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
        sortable: false
    },
    {
        Header: 'Title',
        accessor: 'manuscript_title',
        sortable: false
    },
    {
        Header: 'Journal',
        accessor: 'journal',
        sortable: false
    },
]

export const publishedArticlesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },
]

export const articlesPerInstitutionColumns = [
    {
        Header: 'Institution',
        accessor: 'institution',
    },
    {
        Header: 'Count',
        accessor: 'count',
    },
]

export const institutionMessagesColumns = [
    {
        Header: 'message',
        accessor: 'id',
        sortable: true
    },
    {
        Header: 'created',
        Cell: ({ row }) =>
            // <span>{transformDate(row.original.created)}</span>,
            <span>{row.original.created}</span>,
        sortable: true
    },
    {
        Header: 'type',
        accessor: 'type',
        sortable: true,
    },
    {
        Header: 'sent to',
        accessor: 'sentto',
        sortable: true
    },
    {
        Header: 'journal',
        accessor: 'journal',
        sortable: true
    },
    {
        Header: 'manuscript',
        accessor: 'manuscript',
        sortable: true
    },
    {
        Header: 'DOI',
        accessor: 'doi',
        sortable: true
    },
    {
        Header: 'state',
        accessor: 'state',
        sortable: true
    },
]