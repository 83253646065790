import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { deals } from "./mocks/deals";


export async function getDeals(offset, perPage, sortBy, sortDir, searchValue) {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/deals?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&issn=${searchValue}`)
    //     .catch(handleErrors)
    return  Promise.resolve({ data: deals });
};
