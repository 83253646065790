export const institutionDeals = [
  { "institution": "World Maritime University", "count": 14, "rorid": "https://ror.org/02ma4wv74" },
  { "institution": "Universidad La Gran Colombia", "count": 14, "rorid": "https://ror.org/007kf5222" },
  { "institution": "Josai University", "count": 13, "rorid": "https://ror.org/019c89h28" },
  { "institution": "Göteborg University", "count": 12, "rorid": "https://ror.org/03bs4te22" },
  { "institution": "Preston Institute of Management Sciences and Technology (PIMSAT)", "count": 11, "rorid": "https://ror.org/037xrmj59" },
  { "institution": "Chuo University", "count": 10, "rorid": "https://ror.org/02egmk993" },
  { "institution": "Université de Kolwezi", "count": 9, "rorid": "https://ror.org/01nky7652" },
  { "institution": "Kibi International University", "count": 8, "rorid": "https://ror.org/00js3aw79" },
  { "institution": "American University of Sharjah", "count": 7, "rorid": "https://ror.org/0220qvk04" },
  { "institution": "Kwan Dong University", "count": 6, "rorid": "https://ror.org/03ceheh96" },
  { "institution": "Adeleke University", "count": 5, "rorid": "https://ror.org/04sfka033" },
  { "institution": "Nizhny Novgorod State University", "count": 4, "rorid": "https://ror.org/01yj56c84" },
  { "institution": "Fine Arts Academy in Warsaw", "count": 3, "rorid": "https://ror.org/00d7f8730" },
  { "institution": "College of Visual Arts", "count": 2, "rorid": "https://ror.org/04jt46d36" },
  { "institution": "Universidad Centro Occidental Lisandro Alvarado", "count": 1, "rorid": "https://ror.org/033vjfk17" }
]