export const messages = [
  {
    "id": 274,
    "date": "2022-09-28",
    "type": "p1",
    "send_to": "World Maritime University",
    "delivered_to": "World Maritime University",
    "manuscript_id": "5298706172",
    "doi": "25-645-8238",
    "state": "DLQ"
  },
  {
    "id": 265,
    "date": "2022-09-18",
    "type": "e1",
    "send_to": "Göteborg University",
    "delivered_to": "Göteborg University",
    "manuscript_id": "9786748697",
    "doi": "80-623-3424",
    "state": "DLQ"
  },
  {
    "id": 242,
    "date": "2022-09-14",
    "type": "p1",
    "send_to": "Göteborg University",
    "delivered_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "manuscript_id": "9786748689",
    "doi": "80-623-2865",
    "state": "DLQ"
  },
  {
    "id": 218,
    "date": "2022-08-12",
    "type": "p1",
    "send_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "delivered_to": "-",
    "manuscript_id": "6702268947",
    "doi": "60-135-7342",
    "state": "DLQ"
  },
  {
    "id": 204,
    "date": "2022-07-27",
    "type": "e1",
    "send_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "delivered_to": "-",
    "manuscript_id": "0089466697",
    "doi": "62-917-8521",
    "state": "DLQ"
  },
  {
    "id": 189,
    "date": "2022-07-21",
    "type": "p1",
    "send_to": "Université de Kolwezi",
    "delivered_to": "-",
    "manuscript_id": "0089466691",
    "doi": "62-917-8448",
    "state": "DLQ"
  },
  {
    "id": 182,
    "date": "2022-07-03",
    "type": "p1",
    "send_to": "Universidad La Gran Colombia",
    "delivered_to": "Universidad La Gran Colombia",
    "manuscript_id": "5298704512",
    "doi": "25-645-7373",
    "state": "DLQ"
  },
  {
    "id": 169,
    "date": "2022-06-18",
    "type": "e1",
    "send_to": "Universidad La Gran Colombia",
    "delivered_to": "Josai University",
    "manuscript_id": "9786745534",
    "doi": "80-623-1234",
    "state": "DLQ"
  },
  {
    "id": 166,
    "date": "2022-06-14",
    "type": "p1",
    "send_to": "Université de Kolwezi",
    "delivered_to": "-",
    "manuscript_id": "9786741121",
    "doi": "80-623-7747",
    "state": "DLQ"
  },
  {
    "id": 165,
    "date": "2022-06-12",
    "type": "p1",
    "send_to": "Université de Kolwezi",
    "delivered_to": "-",
    "manuscript_id": "6702268642",
    "doi": "60-135-9982",
    "state": "DLQ"
  },
  {
    "id": 164,
    "date": "2022-05-27",
    "type": "e1",
    "send_to": "Chuo University",
    "delivered_to": "Chuo University",
    "manuscript_id": "0089465902",
    "doi": "62-917-8511",
    "state": "DLQ"
  },
  {
    "id": 162,
    "date": "2022-05-21",
    "type": "p1",
    "send_to": "Fine Arts Academy in Warsaw",
    "delivered_to": "-",
    "manuscript_id": "0089465287",
    "doi": "62-917-8443",
    "state": "DLQ"
  },
  {
    "id": 155,
    "date": "2022-05-11",
    "type": "e1",
    "send_to": "Adeleke University",
    "delivered_to": "Adeleke University",
    "manuscript_id": "6702268655",
    "doi": "61-135-1775",
    "state": "DLQ"
  },
  {
    "id": 154,
    "date": "2022-05-02",
    "type": "e1",
    "send_to": "Chuo University",
    "delivered_to": "Adeleke University",
    "manuscript_id": "0089465654",
    "doi": "59-902-9923",
    "state": "DLQ"
  },
  {
    "id": 148,
    "date": "2022-04-21",
    "type": "p1",
    "send_to": "Fine Arts Academy in Warsaw",
    "delivered_to": "-",
    "manuscript_id": "0089465100",
    "doi": "44-917-7439",
    "state": "DLQ"
  },
]