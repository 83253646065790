export const institutionJournals = [
    {
        "count": "8",
        "name": "Journal of ufology"
    },
    {
        "count": "5",
        "name": "Questions of oncology"
    },
    {
        "count": "4",
        "name": "Astronavigation discussions"
    },
    {
        "count": "4",
        "name": "Biology discoveries"
    },
    {
        "count": "3",
        "name": "Random letters"
    },
]