export const articles = {
    total: 20,
    articles: [{ "id": 1, "doi": "53-649-8439", "journal": "Saccharum coarctatum (Fernald) R. Webster", "manuscript_id": "1527141691", "manuscript_title": "ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur", "updated": "5/10/2021", "lastevent": "10/29/2021", "issn": "2516-8290" },
    { "id": 2, "doi": "90-816-2710", "journal": "Euphorbia helleri Millsp.", "manuscript_id": "9786748689", "manuscript_title": "nascetur ridiculus mus vivamus vestibulum sagittis sapien cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus", "updated": "12/3/2021", "lastevent": "3/1/2022", "issn": "1466-5034" },
    { "id": 3, "doi": "55-512-5330", "journal": "Anisomeridium (Müll. Arg.) M. Choisy", "manuscript_id": "2268318281", "manuscript_title": "aenean fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis", "updated": "3/1/2022", "lastevent": "11/18/2021" },
    { "id": 4, "doi": "07-752-3757", "journal": "Facelis Cass.", "manuscript_id": "3367235245", "manuscript_title": "morbi a ipsum integer a nibh in quis justo maecenas rhoncus", "updated": "6/11/2021", "lastevent": "11/16/2021", "issn": "2516-8290" },
    { "id": 5, "doi": "64-409-8475", "journal": "Carex mariposana L.H. Bailey ex Mack.", "manuscript_id": "6702268947", "manuscript_title": "ultrices mattis odio donec vitae nisi nam ultrices libero non mattis", "updated": "10/16/2021", "lastevent": "9/25/2021", "issn": "2053-3721" },
    { "id": 6, "doi": "13-562-4825", "journal": "Sidalcea elegans Greene", "manuscript_id": "0089466691", "manuscript_title": "augue vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere", "updated": "1/18/2022", "lastevent": "12/4/2021", "issn": "2053-3721" },
    { "id": 7, "doi": "09-001-7735", "journal": "Prunus angustifolia Marshall var. watsonii (Sarg.) Waugh", "manuscript_id": "7947099278", "manuscript_title": "et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante", "updated": "1/9/2022", "lastevent": "8/7/2021" },
    { "id": 8, "doi": "71-289-9009", "journal": "Pectis papposa Harv. & A. Gray var. papposa", "manuscript_id": "3958380808", "manuscript_title": "morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet", "updated": "5/27/2021", "lastevent": "3/18/2022", "issn": "1466-5034" },
    { "id": 9, "doi": "00-602-7900", "journal": "Cardamine diphylla (Michx.) Alph. Wood", "manuscript_id": "9712678016", "manuscript_title": "mauris sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus", "updated": "11/1/2021", "lastevent": "6/29/2021", "issn": "2516-8290" },
    { "id": 10, "doi": "05-032-6716", "journal": "Glycine Willd.", "manuscript_id": "9904497761", "manuscript_title": "nunc donec quis orci eget orci vehicula condimentum curabitur in libero ut", "updated": "11/21/2021", "lastevent": "3/30/2022", "issn": "2053-3721" },
    { "id": 11, "doi": "21-845-5486", "journal": "Montrichardia linifera (Arruda) Schott", "manuscript_id": "1015844820", "manuscript_title": "sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel sem sed sagittis nam", "updated": "3/2/2022", "lastevent": "4/12/2022", "issn": "2053-3721" },
    { "id": 12, "doi": "86-447-2856", "journal": "Yabea microcarpa (Hook. & Arn.) Koso-Pol.", "manuscript_id": "5298706177", "manuscript_title": "malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet", "updated": "10/31/2021", "lastevent": "10/24/2021", "issn": "1473-5644" },
    { "id": 13, "doi": "83-291-7725", "journal": "Pictetia DC.", "manuscript_id": "4667857080", "manuscript_title": "mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in hac habitasse platea dictumst", "updated": "12/18/2021", "lastevent": "4/18/2022", "issn": "1473-5644" },
    { "id": 14, "doi": "81-901-5358", "journal": "Thelypodium integrifolium (Nutt.) Endl. ex Walp. ssp. affine (Greene) Al-Shehbaz", "manuscript_id": "3333942542", "manuscript_title": "donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id", "updated": "10/29/2021", "lastevent": "8/30/2021", "issn": "1473-5644" },
    { "id": 15, "doi": "17-455-2303", "journal": "Acacia redolens Maslin", "manuscript_id": "7646621552", "manuscript_title": "mauris viverra diam vitae quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus", "updated": "6/7/2021", "lastevent": "1/23/2022", "issn": "1473-5644" },
    { "id": 16, "doi": "46-684-0429", "journal": "Cordia globosa (Jacq.) Kunth", "manuscript_id": "3277346498", "manuscript_title": "velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit", "updated": "11/8/2021", "lastevent": "6/19/2021", "issn": "1473-5644" },
    { "id": 17, "doi": "19-663-0659", "journal": "Thelypodium eucosmum B.L. Rob.", "manuscript_id": "9127373193", "manuscript_title": "interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis", "updated": "7/8/2021", "lastevent": "5/15/2021", "issn": "1473-5644" },
    { "id": 18, "doi": "55-289-1985", "journal": "Phacelia novenmillensis Munz", "manuscript_id": "3557436551", "manuscript_title": "sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut", "updated": "10/21/2021", "lastevent": "8/31/2021", "issn": "1473-5644" },
    { "id": 19, "doi": "55-454-0805", "journal": "Vicia hirsuta (L.) Gray", "manuscript_id": "4181513947", "manuscript_title": "tincidunt nulla mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh", "updated": "9/24/2021", "lastevent": "11/18/2021", "issn": "2516-8290", "issn": "1465-2099" },
    { "id": 20, "doi": "98-213-6952", "journal": "Achnatherum speciosum (Trin. & Rupr.) Barkworth", "manuscript_id": "9609214444", "manuscript_title": "potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas ut", "updated": "6/15/2021", "lastevent": "6/18/2021", "issn": "1473-5644" }]
}
