export const messagesPerMonth = [
    {
        "month": "2021-5",
        "count": 145
    },
    {
        "month": "2021-6",
        "count": 139
    },
    {
        "month": "2021-7",
        "count": 178
    },
    {
        "month": "2021-8",
        "count": 161
    },
    {
        "month": "2021-9",
        "count": 190
    },
    {
        "month": "2021-10",
        "count": 201
    },
    {
        "month": "2021-11",
        "count": 193
    },
    {
        "month": "2021-12",
        "count": 230
    },
    {
        "month": "2022-1",
        "count": 217
    },
    {
        "month": "2022-2",
        "count": 260
    },
    {
        "month": "2022-3",
        "count": 259
    },
    {
        "month": "2022-4",
        "count": 248
    },
    {
        "month": "2022-5",
        "count": 267
    }
]