export const enrichmentTypesPercentage = [
  {
    "label": "Ringgold ID mapping",
    "count": "8"
  },
  {
    "label": "ROR affiliation search",
    "count": "80"
  },
  {
    "label": "ORCID affiliation",
    "count": "12"
  },
]