export const lastYearArticles = [
    {
        "month": "2021-5",
        "count": 189
    },
    {
        "month": "2021-6",
        "count": 214
    },
    {
        "month": "2021-7",
        "count": 244
    },
    {
        "month": "2021-8",
        "count": 218
    },
    {
        "month": "2021-9",
        "count": 268
    },
    {
        "month": "2021-10",
        "count": 303
    },
    {
        "month": "2021-11",
        "count": 329
    },
    {
        "month": "2021-12",
        "count": 298
    },
    {
        "month": "2022-1",
        "count": 360
    },
    {
        "month": "2022-2",
        "count": 352
    },
    {
        "month": "2022-3",
        "count": 389
    },
    {
        "month": "2022-4",
        "count": 402
    },
    {
        "month": "2022-5",
        "count": 413
    }
]