export const deals = [{
  "institution": "R&P deal Adeleke University",
  "count": 17,
  "type": "R&P",
  "rorid": "https://ror.org/007kf5222"
}, {
  "institution": "Fixed APC deal UKB",
  "count": 16,
  "type": "direct billing",
  "rorid": "https://ror.org/007kf5222"
}, {
  "institution": "R&P deal Kwan Dong University",
  "count": 16,
  "type": "R&P",
  "rorid": "https://ror.org/007kf5222"
}, {
  "institution": "National deal China",
  "count": 15,
  "type": "S2O",
  "rorid": "https://ror.org/007kf5222"
}]