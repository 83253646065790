import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { getArticlesPerFunder } from "../../helpers/statistics";
import { Field, withFormik } from "formik";
import { getInstitutionArticlesPerMonth, getInstitutionJournals, getInstitutionMessages, getFunderArticles } from "../../helpers/institutions";
import {
    PieChart,
    Table,
    Card,
    Loader,
    ErrorMessage,
    AsyncInput,
    BarChart,
    loadRorOptions,
    NavigationTabs,
    ArticleDetailsModal,
    CustomTable
} from "pubtrack-frontend-library";
import { getArticleDetails, updateArticleDetails } from "../../helpers/articles";
import { userSettings } from "../../user-settings";
import { funderMessagesColumns, inPipeLineArticlesColumns, publishedArticlesColumns } from "./columnsData";
import { itemsPerPage } from "../../constants";

const Funders = () => {
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [selectedFunder, setSelectedFunder] = useState(null);
    const [error, setError] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [institutionArticlesPerMonth, setInstitutionArticlesPerMonth] = useState(null);
    const [institutionJournals, setInstitutionJournals] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [articleDetails, setArticleDetails] = useState(null);

    const [articlesPerFunder, setArticlesPerFunder] = useState([]);

    const [inPipeLineArticles, setInPipelineArticles] = useState(null);
    const [publishedArticles, setPublishedArticles] = useState(null);

    const articlesPerInstitutionColumns = React.useMemo(
        () => [
            {
                Header: 'funder',
                accessor: 'institution',
            },
            {
                Header: 'count',
                accessor: 'count',
            },
        ], []
    );

    useEffect(() => {
        if (selectedFunder) {
            setLoading(true);

            const fetchData = async () => {
                try {
                    const [articlesPerMonthResponse, journalsResponse, inPipelineArticlesResponse, publishedArticlesResponse] = await Promise.all([
                        getInstitutionArticlesPerMonth(encodeURIComponent(selectedFunder)),
                        getInstitutionJournals(encodeURIComponent(selectedFunder)),
                        getFunderArticles(encodeURIComponent(selectedFunder), 'production'),
                        getFunderArticles(encodeURIComponent(selectedFunder), 'published'),
                    ]);
                    setInstitutionArticlesPerMonth(articlesPerMonthResponse.data);
                    setInstitutionJournals(journalsResponse.data);
                    setInPipelineArticles(inPipelineArticlesResponse.data);
                    setPublishedArticles(publishedArticlesResponse.data);
                } catch (error) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedFunder]);

    useEffect(() => {
        const fetchArticlesPerFunder = async () => {
            try {
                setLoading(true);
                const res = await getArticlesPerFunder();
                setArticlesPerFunder(res.data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchArticlesPerFunder();
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            setIsOpen(true);
        };
    }, [selectedMessage]);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const getMessagesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getInstitutionMessages(encodeURIComponent(selectedFunder), searchValue, offset, itemsPerPage, sortBy, sortDir)
            .then(res => ({ data: res.data, total: res.data.length }))
    };

    const updateArticle = () => {
        return updateArticleDetails(selectedMessage, articleDetails);
    };

    const handleSelectArticle = row => {
        const id = row && row.original ? row.original.id : null;
        setSelectedMessage(id);
    };

    const handleSelectMessage = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    const handleSelectFunder = row => {
        const rorId = row && row.original ? row.original["rorid"] : null;
        setSelectedFunder(rorId);
    };

    const tabs = [
        {
            id: '1',
            title: 'In pipeline',
            content: (
                <>
                    {inPipeLineArticles &&
                        <Table
                            data={inPipeLineArticles}
                            columns={inPipeLineArticlesColumns}
                            sortBy={sortBy}
                            sortDir={sortDir}
                            setSortBy={setSortBy}
                            setSortDir={setSortDir}
                            onSelectRow={handleSelectArticle}
                        />}
                </>
            ),
        },
        {
            id: '2',
            title: 'Published',
            content: (
                <>
                    {publishedArticles && (
                        <Table
                            data={publishedArticles}
                            columns={publishedArticlesColumns}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </>

            ),
        },
        {
            id: '3',
            title: 'Messages',
            content: (
                <CustomTable
                    columns={funderMessagesColumns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getMessagesData}
                    getFilterData={null}
                    sortByDefault="id"
                    sortDirDefault="desc"
                    search={false}
                    onSelectRow={handleSelectMessage}
                />
            ),
        },
    ];

    const renderArticlesPerFunder = () => {
        if (!selectedFunder) {
            return (
                <Table
                    title="Articles per funder"
                    data={articlesPerFunder}
                    columns={articlesPerInstitutionColumns}
                    onSelectRow={handleSelectFunder}
                    exportAvailable={true}
                />
            );
        }
        return null;
    };

    const renderFunderCharts = () => {
        if (!loading && !error && selectedFunder && institutionArticlesPerMonth && institutionJournals) {
            return (
                <>
                    <MDBRow className="mt-4 d-flex justify-content-center">
                        <MDBCol md='8' className="mb-5 mt-4">
                            <Card
                                title="Funder articles per month:"
                                content={<BarChart data={institutionArticlesPerMonth} xAxisKey="month" yAxisKey="count" />}
                            />
                        </MDBCol>
                        <MDBCol md='4' className="mb-5 mt-4">
                            <Card
                                title="Funder journals:"
                                content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                            />
                        </MDBCol>
                    </MDBRow>
                    <NavigationTabs tabs={tabs} />
                </>
            );
        }
        return null;
    };


    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <h5 className="text-center my-4">Funders search</h5>
                <div className="d-flex justify-content-end mb-4">
                    <Field component={AsyncInput} loadOptions={loadRorOptions} setValue={setSelectedFunder} placeholder="Search" />
                </div>

                {renderArticlesPerFunder()}

                {loading && <Loader />}
                {!loading && error && <ErrorMessage />}
                {!loading && !error && selectedFunder && renderFunderCharts()}

                <ArticleDetailsModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    articleDetails={articleDetails}
                    tabItems={userSettings.details}
                    updateArticle={updateArticle}
                />
            </div>
        </div>
    );
};


export default withFormik({})(Funders);