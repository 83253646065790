import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { Field, withFormik } from "formik";
import { getInstitutionDataPerMonth, getInstitutionJournals, getInstitutionMessages, getInstitutionArticles } from "../../helpers/institutions";
import {
    PieChart,
    Table,
    Card,
    Loader,
    ErrorMessage,
    AsyncInput,
    MultiBarsChart,
    loadRorOptions,
    ArticleDetailsModal,
    CustomTable,
    NavigationTabs
} from "pubtrack-frontend-library";
import { getArticlesPerInstitution } from "../../helpers/statistics";
import { getArticleDetails, updateArticleDetails } from "../../helpers/articles";
import { userSettings } from "../../user-settings";
import { articlesPerInstitutionColumns, inPipeLineArticlesColumns, institutionMessagesColumns, publishedArticlesColumns } from "./columnsData";
import { itemsPerPage } from "../../constants";

const Institutions = () => {
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [articlesPerInstitution, setArticlesPerInstitution] = useState([]);
    const [dataPerMonth, setDataPerMonth] = useState(null);
    const [institutionJournals, setInstitutionJournals] = useState(null);

    const [articleDetails, setArticleDetails] = useState(null);
    const [messageDetails, setMessageDetails] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isArticleOpen, setIsArticleOpen] = useState(false);
    const [isMessageOpen, setIsMessageOpen] = useState(false);

    const [selectedInstitution, setSelectedInstitution] = useState(null);

    const [inPipeLineArticles, setInPipelineArticles] = useState(null);
    const [publishedArticles, setPublishedArticles] = useState(null);

    useEffect(() => {
        setLoading(true);

        getArticlesPerInstitution()
            .then(res => {
                setArticlesPerInstitution(res.data);
                setLoading(false);
            })
            .catch(() => setError(true))
    }, []);

    useEffect(() => {
        if (selectedInstitution) {
            setLoading(true);
            const fetchData = async () => {
                try {
                    const [
                        dataPerMonthResponse,
                        institutionJournalsResponse,
                        inPipelineArticlesResponse,
                        publishedArticlesResponse,
                    ] = await Promise.all([
                        getInstitutionDataPerMonth(encodeURIComponent(selectedInstitution)),
                        getInstitutionJournals(encodeURIComponent(selectedInstitution)),
                        getInstitutionArticles(encodeURIComponent(selectedInstitution), 'production'),
                        getInstitutionArticles(encodeURIComponent(selectedInstitution), 'published')])

                    setDataPerMonth(dataPerMonthResponse.data);
                    setInstitutionJournals(institutionJournalsResponse.data);
                    setInPipelineArticles(inPipelineArticlesResponse.data);
                    setPublishedArticles(publishedArticlesResponse.data);
                } catch (error) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [selectedInstitution]);



    const handleSelectArticle = row => {
        const articleId = row && row.original ? row.original.id : null;
        setSelectedArticle(articleId);
    };

    const handleSelectMessage = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    const handleSelectInstitution = row => {
        const rorId = row && row.original ? row.original["rorid"] : null;
        setSelectedInstitution(rorId);
    };

    useEffect(() => {
        if (selectedArticle) {
            getArticleDetails(selectedArticle)
                .then(res => setArticleDetails(res.data))
                .then(setIsArticleOpen(true))
                .then(setSelectedArticle(null))
        }
    }, [selectedArticle]);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setMessageDetails(res.data))
                .then(setIsMessageOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const updateArticle = () => {
        return updateArticleDetails(selectedMessage, articleDetails);
    };

    const getMessagesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getInstitutionMessages(encodeURIComponent(selectedInstitution), searchValue, offset, itemsPerPage, sortBy, sortDir)
            .then(res => ({ data: res.data, total: res.data.length }))
    };

    const tabs = [
        {
            id: '1',
            title: 'In pipeline',
            content: (
                <div>
                    {inPipeLineArticles && (
                        <Table
                            data={inPipeLineArticles}
                            columns={inPipeLineArticlesColumns}
                            sortBy={sortBy}
                            sortDir={sortDir}
                            setSortBy={setSortBy}
                            setSortDir={setSortDir}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </div>
            ),
        },
        {
            id: '2',
            title: 'Published',
            content: (
                <div>
                    {publishedArticles && (
                        <Table
                            data={publishedArticles}
                            columns={publishedArticlesColumns}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </div>
            ),
        },
        {
            id: '3',
            title: 'Messages',
            content: (
                <CustomTable
                    columns={institutionMessagesColumns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getMessagesData}
                    getFilterData={null}
                    onSelectRow={handleSelectMessage}
                    sortByDefault="id"
                    sortDirDefault="desc"
                    search={false}
                />
            ),
        },
    ];


    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <h5 className="text-center my-4">Institution search</h5>
                <div className="d-flex justify-content-end mb-4">
                    <Field component={AsyncInput} loadOptions={loadRorOptions} setValue={setSelectedInstitution} placeholder="Search" />
                </div>

                {!selectedInstitution && (
                    <div className="my-5">
                        <Table
                            title="Articles per institution"
                            data={articlesPerInstitution}
                            columns={articlesPerInstitutionColumns}
                            onSelectRow={handleSelectInstitution}
                            exportAvailable={true}
                        />
                    </div>
                )}
                {loading && <Loader />}
                {!loading && error && <ErrorMessage />}
                {!loading && selectedInstitution && (
                    <>
                        {dataPerMonth && institutionJournals && (
                            <MDBRow className="mt-4 d-flex justify-content-center">
                                <MDBCol md="8" className="mb-5 mt-4">
                                    <Card
                                        title="Articles/messages per month:"
                                        content={
                                            <MultiBarsChart
                                                data={dataPerMonth}
                                                xAxisKey="month"
                                                yAxisKey={["articles", "messages"]}
                                                yAxisLabel="count"
                                                labels={["Articles", "Messages"]}
                                            />
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md="4" className="mb-5 mt-4">
                                    <Card
                                        title="Per journal:"
                                        content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                                    />
                                </MDBCol>
                            </MDBRow>
                        )}
                        <NavigationTabs tabs={tabs} />
                    </>
                )}
                <ArticleDetailsModal
                    isOpen={isArticleOpen}
                    setIsOpen={setIsArticleOpen}
                    articleDetails={articleDetails}
                    tabItems={userSettings.details}
                    updateArticle={updateArticle}
                />
                <ArticleDetailsModal
                    isOpen={isMessageOpen}
                    setIsOpen={setIsMessageOpen}
                    articleDetails={messageDetails}
                    tabItems={userSettings.details}
                />
            </div>
        </div>
    );
};


export default withFormik({})(Institutions);