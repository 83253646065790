import React, { useEffect, useState } from "react";
import {
    Table,
    Tabs,
    InfoSection
} from "pubtrack-frontend-library";
import { getEligibilities, getEligibilityDetails, getHistoricEligibilities } from "../../helpers/eligibilities";
import { MDBTabPane } from "mdbreact";
import { TableModal } from "pubtrack-frontend-library/dist/components";


const Eligibility = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [eligibilityDetails, setEligibilityDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [eligibilities, setEligibilities] = useState([]);
    const [historicEligibilities, setHistoricEligibilities] = useState([]);

    const tabs = ["Positive", "Negative"];
    const manuscriptId = eligibilityDetails?.manuscript || "-";
    const title = eligibilityDetails?.title || "-";
    const journal = eligibilityDetails?.journal || "-";

    useEffect(() => {
        getEligibilities()
            .then(res => setEligibilities(...res.data));

        getHistoricEligibilities()
            .then(res => setHistoricEligibilities(res.data));
    }, []);

    const inProcessEligibilitiesColumns = React.useMemo(
        () => [
            {
                Header: 'recipient',
                accessor: 'recipient',
                sortable: false
            },
            {
                Header: 'since',
                accessor: 'since',
                sortable: false
            },
            {
                Header: 'position',
                accessor: 'position',
                sortable: false
            },
        ], []
    );

    const historicEligibilitiesColumns = React.useMemo(
        () => [
            {
                Header: 'article',
                accessor: 'article',
                sortable: false
            },
            {
                Header: 'result',
                accessor: 'state',
                sortable: false
            },
        ], []
    );

    const responseEligibilitiesColumns = React.useMemo(
        () => [
            {
                Header: 'institution/funder',
                accessor: 'institution',
                sortable: false
            },
            {
                Header: 'nr requests',
                accessor: 'requests',
                sortable: false
            },
        ], []
    );

    const eligibilityDetailsColumns = React.useMemo(
        () => [
            {
                Header: 'recipient',
                accessor: 'recipient',
                sortable: false
            },
            {
                Header: 'status',
                accessor: 'status',
                sortable: false
            },
            {
                Header: 'when',
                accessor: 'when',
                sortable: false
            },
        ], []
    );

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        if (selectedMessage) {
            setIsOpen(true);
            getEligibilityDetails(selectedMessage)
                .then(res => setEligibilityDetails(...res.data?.header))
        }
    }, [selectedMessage]);

    const getEligibilityDetailsData = () => {
        setSelectedMessage(null);

        return getEligibilityDetails(selectedMessage)
            .then(res => ({ data: res.data.flow }))
    };


    return (
        <div className="container">
            <div className="my-5">
                <Table
                    data={eligibilities.pending || []}
                    columns={inProcessEligibilitiesColumns}
                    onSelectRow={onSelectRow}
                    exportAvailable={true}
                    title={"In process:"}
                />
            </div>

            <div className="my-5">
                <Table
                    data={historicEligibilities}
                    columns={historicEligibilitiesColumns}
                    onSelectRow={onSelectRow}
                    title={"Historic:"}
                />
            </div>

            <div className="mb-5">
                <h5 className="text-center mt-5 mb-4">Response:</h5>
                <Tabs
                    tabs={tabs}
                    content={
                        <>
                            <MDBTabPane tabId="Positive" role="tabpanel">
                                <Table
                                    data={eligibilities.accepted || []}
                                    columns={responseEligibilitiesColumns}
                                />
                            </MDBTabPane>
                            <MDBTabPane tabId="Negative" role="tabpanel">
                                <Table
                                    data={eligibilities.rejected || []}
                                    columns={responseEligibilitiesColumns}
                                />
                            </MDBTabPane>
                        </>
                    }
                />
            </div>

            <TableModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                columns={eligibilityDetailsColumns}
                getTableData={getEligibilityDetailsData}
                search={false}
                itemsPerPage={null}
                content={<div className="mb-5">
                    <h5 className="text-center mb-4 mt-4">Eligibility workflow</h5>
                    <InfoSection title="Manuscript ID" info={manuscriptId} />
                    <InfoSection title="Title" info={title} />
                    <InfoSection title="Journal" info={journal} />
                </div>}
            />
        </div>
    );
};


export default Eligibility;