export const messagesTypes = [
    {
        "type": "P1",
        "count": 176
    },
    {
        "type": "P2",
        "count": 55
    },
    {
        "type": "E1",
        "count": 62
    },
    {
        "type": "E2",
        "count": 28
    },
];