export const historicEligibilities = [
    {
        "article": "Reactive mission-critical core",
        "id": 111,
        "state": "ACCEPTED"
    },
    {
        "article": "Open-source tertiary process improvement",
        "id": 88,
        "state": "ACCEPTED"
    },
    {
        "article": "Total attitude-oriented alliance",
        "id": 104,
        "state": "PENDING"
    },
    {
        "article": "Re-engineered 4th generation frame",
        "id": 83,
        "state": "ACCEPTED"
    },
    {
        "article": "Total mission-critical standardization",
        "id": 86,
        "state": "REJECTED"
    },
    {
        "article": "Monitored contextually-based internet solution",
        "id": 112,
        "state": "REJECTED"
    }
];