import React from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import styled, { withTheme } from 'styled-components';

const NavbarWrapper = styled.div`
    .navbar-color {
        background-color: ${props => props.theme.navbarLogoBackgroundColor} !important;
    }

    nav .nav-link:hover {
        text-decoration: underline !important;
    }

    .navbar {
        box-shadow: none !important;
    }

    .logotype-navbar {
        background-color: ${props => props.theme.navbarLogoBackgroundColor};
        height: 32px;
        display: flex;
        align-items: center;
    }

    .logotype-divider {
        font-weight: bold;
        font-size: 1.4rem;
        color: #fff;
        padding: 0 18px;
      }
`

const Navbar = () => {

    return (
        <NavbarWrapper>
            <MDBNavbar color="navbar-color px-5" dark expand="md">
                <MDBNavbarBrand>
                    <div className="logotype-navbar">
                        <div className="logotype-title">Publication Tracker</div>
                        <div className="logotype-divider"> | </div>
                        <div>Demo publisher</div>
                    </div>
                </MDBNavbarBrand>
            </MDBNavbar>
        </NavbarWrapper>
    );
};


export default withTheme(Navbar);