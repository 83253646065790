export const institutionArticlesPerMonth = [
    {
        "month": "2021-5",
        "count": 18
    },
    {
        "month": "2021-6",
        "count": 21
    },
    {
        "month": "2021-7",
        "count": 24
    },
    {
        "month": "2021-8",
        "count": 21
    },
    {
        "month": "2021-9",
        "count": 26
    },
    {
        "month": "2021-10",
        "count": 30
    },
    {
        "month": "2021-11",
        "count": 32
    },
    {
        "month": "2021-12",
        "count": 29
    },
    {
        "month": "2022-1",
        "count": 32
    },
    {
        "month": "2022-2",
        "count": 35
    },
    {
        "month": "2022-3",
        "count": 34
    },
    {
        "month": "2022-4",
        "count": 36
    },
    {
        "month": "2022-5",
        "count": 39
    }
]