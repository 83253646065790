export const eligibilityDetails = {
  "header": [
    {
      "manuscript": "jcb.202101075", "title": "Extracellular vesicles from neurons promote neural induction of stem cells through cyclin D1Neural induction by neuron extracellular vesicles", "journal": "Journal of Cell Biology"
    }
  ],
  "flow": [
    {
      "recipient": "Economics and Management School, Wuhan University, Wuhan 430072",
      "status": "failed",
      "when": "10-may",
      "id": 1,
    }, {
      "recipient": "School of Management, Shenyang University of Technology, Shenyang 110870",
      "status": "reject",
      "when": "11-may",
      "id": 1,
    }, {
      "recipient": "Department of Pharmacy, BGC Trust University Bangladesh, Chittagong 4381",
      "status": "failed",
      "when": "11-may",
      "id": 1,
    }, {
      "recipient": "International Development Research Center",
      "status": "failed",
      "when": "11-may",
      "id": 1,
    }, {
      "recipient": "Chinese Academy of Sciences (CAS)",
      "status": "open",
      "when": "today",
      "id": 1,
    }
  ]
}