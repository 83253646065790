import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    BarChart,
    ArticleDetailsModal,
    Card,
    PieChart,
} from "pubtrack-frontend-library";
import { getMessagesPerMonth, getMessagesTypes, getMessages } from "../../helpers/messages";
import { getArticleDetails, updateArticleDetails } from "../../helpers/articles";
import { userSettings } from "../../user-settings";
import { itemsPerPage } from "../../constants";
import MessagesTable from "./MessagesTable";

const Messages = () => {
    const [messagesPerMonth, setMessagesPerMonth] = useState([]);
    const [messagesTypes, setMessagesTypes] = useState([]);
    const [articleDetails, setArticleDetails] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [defaultTab, setDefaultTab] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const getMessagesData = ({ filterValue, ror }) => {
        return getMessages(filterValue, ror)
            .then(res => ({ data: res.data.messages, total: res.data.total }))
    };

    useEffect(() => {
        getMessagesPerMonth()
            .then(res => setMessagesPerMonth(res.data));

        getMessagesTypes()
            .then(res => setMessagesTypes(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const onSelectMessage = row => {
        const messageId = row && row.original ? row.original["doi"] : null;
        setDefaultTab("Messages");
        setSelectedMessage(messageId);
    };

    const updateArticle = () => {
        return updateArticleDetails(selectedMessage, articleDetails);
    };

    const getFilterData = () => {
        return Promise.resolve([
            {
                id: 'all_',
                issn: '', //TODO: FilterPanel component refactor needed (takes 'issn' or 'title' prop as value)
                title: 'All messages'
            },
            {
                id: 'e1',
                title: 'e1'
            },
            {
                id: 'e2',
                title: 'e2'
            },
            {
                id: 'p1',
                title: 'p1'
            },
            {
                id: 'p2',
                title: 'p2'
            }
        ]);
    };

    return (
        <div className="container">
            <MDBRow className="mt-5 d-flex justify-content-center">
                <MDBCol md='8' className="mb-5 mt-4">
                    <Card
                        title="Messages per month:"
                        content={
                            <BarChart
                                data={messagesPerMonth}
                                xAxisKey="month"
                                yAxisKey="count"
                                color={"secondaryColor"}
                            />}
                    />
                </MDBCol>
                <MDBCol md='4' className="mb-5 mt-4">
                    <Card
                        title="Messages types:"
                        content={
                            <PieChart
                                data={messagesTypes}
                                labelKey="type"
                                valueKey="count"
                            />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="mb-5">
                <MessagesTable
                    itemsPerPage={itemsPerPage}
                    getTableData={getMessagesData}
                    getFilterData={getFilterData}
                    onSelectRow={onSelectMessage}
                    exportAvailable={true}
                />
            </div>

            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
                updateArticle={updateArticle}
                defaultTab={defaultTab}
            />
        </div>
    );
};


export default Messages;