export const funderMessagesColumns = [
    {
        Header: 'message',
        accessor: 'id',
        sortable: true
    },
    {
        Header: 'created',
        Cell: ({ row }) => <span>{row.original.date}</span>,
        sortable: true
    },
    {
        Header: 'type',
        accessor: 'type',
        sortable: true,
    },
    {
        Header: 'sent to',
        accessor: 'send_to',
        sortable: true
    },
    {
        Header: 'journal',
        accessor: 'journal',
        sortable: true
    },
    {
        Header: 'manuscript_id',
        accessor: 'manuscript_id',
        sortable: true
    },
    {
        Header: 'DOI',
        accessor: 'doi',
        sortable: true
    },
    {
        Header: 'state',
        accessor: 'state',
        sortable: true
    },
]

export const publishedArticlesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Title',
        accessor: 'title',
    },

]

export const inPipeLineArticlesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
        sortable: false
    },
    {
        Header: 'Title',
        accessor: 'manuscript_title',
        sortable: false
    },
    {
        Header: 'Journal',
        accessor: 'journal',
        sortable: false
    },
]

export const articlesPerInstitutionColumns = [
    {
        Header: 'Funder',
        accessor: 'institution',
    },
    {
        Header: 'Count',
        accessor: 'count',
    },
];
