import React, { useState, useEffect } from "react";
import { MDBBox, MDBCol, MDBRow, } from "mdbreact";
import { Field, withFormik } from "formik";
import { Table, SearchPanel, Loader, Pagination, ErrorMessage, FilterPanel, AsyncInput, loadRorOptions } from "pubtrack-frontend-library";

const ArticlesTable = ({ columns, getTableData, getFilterData, onSelectRow, itemsPerPage = 10, search = true, title, sortByDefault = 'id', sortDirDefault = 'asc' }) => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [sortBy, setSortBy] = useState(sortByDefault);
    const [sortDir, setSortDir] = useState(sortDirDefault);
    const [searchValue, setSearchValue] = useState("");
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [error, setError] = useState(false);

    const [rorSearchValue, setRorSearchValue] = useState("");

    useEffect(() => {
        if (typeof (getFilterData) === "function") {
            getFilterData()
                .then(data => setFilterOptions(data));
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        getTableData({ searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue, ror: rorSearchValue })
            .then(({ data, total }) => {
                if (isMounted) {
                    setTableData(data || []);
                    setPageCount(Math.ceil(total / itemsPerPage));
                    setLoading(false);
                };
            })
            .catch(() => setError(true))
        return () => { isMounted = false };
    }, [offset, sortBy, sortDir, filterValue, rorSearchValue]);

    const handlePageClick = data => {
        const selectedPage = data.selected;
        const offset = selectedPage * itemsPerPage;
        setCurrentPage(selectedPage);
        setOffset(offset + 1);
    };

    const onSearch = () => {
        let isMounted = true;
        setLoading(true);

        getTableData({ searchValue, offset, itemsPerPage, sortBy, sortDir, filterValue, ror: rorSearchValue })
            .then(({ data, total }) => {
                if (isMounted) {
                    setOffset(1);
                    setCurrentPage(0);
                    setTableData(data || []);
                    setPageCount(Math.ceil(total / itemsPerPage));
                    setLoading(false);
                };
            })
            .catch(() => setError(true))
        return () => { isMounted = false };
    };

    return (
        <>
            {title && <h5 className="text-center my-4">{title}</h5>}
            <MDBRow className="flex-wrap">
                <MDBCol sm="12" md="12" lg="4" className="d-flex align-items-center">
                    {
                        getFilterData &&
                        <MDBBox my={3}>
                            <FilterPanel
                                title=""
                                selectOptions={filterOptions}
                                setFilterValue={setFilterValue}
                            />
                        </MDBBox>
                    }
                </MDBCol>
                <MDBCol sm="12" md="12" lg="5" className="d-flex align-items-center">
                    <Field
                        component={AsyncInput}
                        loadOptions={loadRorOptions}
                        setValue={setRorSearchValue}
                        placeholder="All institutions"
                    />
                </MDBCol>

                <MDBCol sm="12" md="12" lg="3" className="d-flex align-items-center" order={12}>
                    {
                        search &&
                        <MDBBox my={3}>
                            <SearchPanel
                                onSearch={onSearch}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                placeholder={'Search by article'}
                            />
                        </MDBBox>
                    }
                </MDBCol>
            </MDBRow>

            {
                loading ?
                    <Loader />
                    :
                    error ?
                        <ErrorMessage />
                        :
                        <>
                            <Table
                                data={tableData}
                                columns={columns}
                                sortBy={sortBy}
                                sortDir={sortDir}
                                onSelectRow={onSelectRow}
                                setSortBy={setSortBy}
                                setSortDir={setSortDir}
                                exportAvailable={false}
                            />
                            <MDBRow>
                                <MDBCol sm="12" md="12" lg="4" className="d-flex align-items-center justify-content-start">
                                    {
                                        itemsPerPage &&
                                        <MDBBox my={3}>
                                            <Pagination
                                                handlePageClick={handlePageClick}
                                                pageCount={pageCount}
                                                visible={tableData.length}
                                                currentPage={currentPage}
                                            />
                                        </MDBBox>
                                    }
                                </MDBCol>
                            </MDBRow>
                        </>
            }
        </>
    )
}

export default withFormik({})(ArticlesTable);