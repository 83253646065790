export const institutionDataPerMonth = [
    {
        "month": "2022-4",
        "articles": 8,
        "messages": 16
    },
    {
        "month": "2022-5",
        "articles": 15,
        "messages": 16
    },
    {
        "month": "2022-6",
        "articles": 14,
        "messages": 17
    },
    {
        "month": "2022-7",
        "articles": 17,
        "messages": 18
    },
    {
        "month": "2022-8",
        "articles": 16,
        "messages": 24
    },
    {
        "month": "2022-9",
        "articles": 23,
        "messages": 28
    }
]