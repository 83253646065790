import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
    ...theme,

    primaryColor: '#abd699',
    secondaryColor: '#75c9b7',

    accordionHeaderBackgroundColor: '#abd699',

    modalHeaderBackgroundColor: '#abd699',

    tabItemColor: '#16123f',

    navbarBackgroundColor: '#abd699',
    navbarLogoBackgroundColor: '#06101a',
    navbarButtonHoverColor: '#75c9b7',
    logoutButtonBackgroundColor: '#06101a',

    paginationButtonBackgroundColor: '#abd699',
    paginationButtonHoverBackgroundColor: '#75c9b7',

    tableHeaderBackgroundColor: '#abd699',

    searchButtonBackgroundColor: '#abd699',

    defaultButtonBackgroundColor: '#abd699',
    primaryButtonBackgroundColor: '#16123f',
    secondaryButtonBackgroundColor: '#75c9b7',
    colorsPalette: [
        "#abd699", "#75c9b7", "#379683", "#edf5e1"
    ]
}

const App = () => (
    <ThemeProvider theme={customTheme}>
        <GlobalStyles />
        <Routes />
        <Footer />
    </ThemeProvider>
);

export default App;