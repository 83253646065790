export const eligibilities = [
  {
    "pending": [
      {
        "recipient": "Economics and Management School, Wuhan University, Wuhan 430072",
        "since": "2 days",
        "position": 3,
        "id": 1,
      }, {
        "recipient": "School of Management, Shenyang University of Technology, Shenyang 110870",
        "since": "2 days",
        "position": 2,
        "id": 1,
      }, {
        "recipient": "Department of Pharmacy, BGC Trust University Bangladesh, Chittagong 4381",
        "since": "1 day",
        "position": 1,
        "id": 1,
      }, {
        "recipient": "International Development Research Center",
        "since": "1 day",
        "position": 1,
        "id": 1,
      }, {
        "recipient": "Chinese Academy of Sciences (CAS)",
        "since": "today",
        "position": 4,
        "id": 1,
      }
    ],
    "accepted": [
      {
        "institution": "Department of Traditional Chinese Medicine",
        "requests": 3,
      }, {
        "institution": "College of Transportation, Jilin University, Changchun 130022",
        "requests": 2,
      }, {
        "institution": "School of Civil Engineering, North China University of Technology, Beijing 100144",
        "requests": 6,
      }, {
        "institution": "Tamale Technical University, Tamale",
        "requests": 4,
      }
    ],
    "rejected": [
      {
        "institution": "School of Civil Engineering, North China University of Technology, Beijing 100144",
        "requests": 3,
      }, {
        "institution": "Facultad de Medicina, Universidad del Azuay, Cuenca",
        "requests": 2,
      }, {
        "institution": "Faculty of Information Technology, Beijing University of Technology, Beijing 100124",
        "requests": 2,
      }]
  }
]
