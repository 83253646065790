import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import { getAgreements } from "../../helpers/statistics";
import { getDeals } from "../../helpers/deals";
import {
    Card,
    CustomTable,
    MultiBarsChart,
    TableModal,
    shortenTitle
} from "pubtrack-frontend-library";
import { getInstitutionDetails } from "../../helpers/institutions";


const Deals = () => {
    const [agreements, setAgreements] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'DOI',
                accessor: 'doi',
                sortable: false
            },
            {
                Header: 'Title',
                accessor: 'title',
                sortable: false
            },
            {
                Header: 'Journal',
                accessor: 'journal',
                sortable: false
            },
        ], []
    );

    const dealsColumns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'institution',
                sortable: false
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false
            },
            {
                Header: 'Number of articles',
                accessor: 'count',
                sortable: false
            },
        ], []
    );

    const getDataTable = data => {
        if (data) {
            return data.map(item => {
                const dealTitle = item.institution ? shortenTitle(item.institution, 100) : '';
                return {
                    institution: dealTitle,
                    count: item.count,
                    rorid: item.rorid,
                    type: item.type,
                };
            }).filter(item => item.institution !== "N.A.")
        }
        return [];
    };

    const getDealsData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getDeals(offset, itemsPerPage, sortBy, sortDir, searchValue)
            .then(res => ({ data: getDataTable(res.data) }))
    };

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["rorid"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        getAgreements()
            .then(res => setAgreements(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            setIsOpen(true);
        };
    }, [selectedMessage]);

    const getDataTable2 = data => {
        if (data) {
            return data.map(item => {
                const title = item.title ? shortenTitle(item.title, 100) : '';
                const journal = item.journal ? shortenTitle(item.journal, 100) : '';
                return {
                    title: title,
                    journal: journal,
                    doi: item.doi,
                    prioragreement: item.prioragreement,
                    rorid: item.rorid,
                };
            });
        }
        return [];
    };

    const getInstitutionDetailsData = () => {
        const rorId = decodeURIComponent(selectedMessage);
        setSelectedMessage(null);

        return getInstitutionDetails(rorId)
            .then(res => ({ data: getDataTable2(res.data) }))
    };


    return (
        <div className="container">
            <MDBRow className="my-5 d-flex justify-content-center">
                <MDBCol md='6' className="mt-4 mb-3">
                    <Card
                        title="Published articles:"
                        content={
                            <MultiBarsChart
                                data={agreements}
                                xAxisKey="month"
                                yAxisKey={["prioragreement", "noprioragreement"]}
                                yAxisLabel="count"
                                labels={["Prior agreement", "No prior agreement"]}
                            />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="mb-5">
                <CustomTable
                    columns={dealsColumns}
                    getTableData={getDealsData}
                    onSelectRow={onSelectRow}
                    search={false}
                    itemsPerPage={null}
                    exportAvailable={true}
                />
            </div>

            <TableModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                columns={columns}
                getTableData={getInstitutionDetailsData}
                search={false}
                itemsPerPage={null}
                exportAvailable={true}
            />
        </div>
    );
};


export default Deals;