export const dealsDetails = [
  {
    "title": "Sharable even-keeled orchestration",
    "journal": "Journal of ufology",
    "doi": "25-645-8238",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Function-based dedicated application",
    "journal": "Journal of ufology",
    "doi": "62-562-8173",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Networked well-modulated function",
    "journal": "Journal of ufology",
    "doi": "22-414-5725",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "De-engineered 24 hour installation",
    "journal": "Questions of oncology",
    "doi": "78-348-4080",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Reactive mission-critical core",
    "journal": "Questions of oncology",
    "doi": "33-920-2769",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Open-source tertiary process improvement",
    "journal": "Questions of oncology",
    "doi": "01-746-7626",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Total attitude-oriented alliance",
    "journal": "Questions of oncology",
    "doi": "28-690-3724",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Public-key transitional throughput",
    "journal": "Astronavigation discussions",
    "doi": "24-227-7098",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Re-engineered 4th generation frame",
    "journal": "Astronavigation discussions",
    "doi": "80-623-2865",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Total mission-critical standardization",
    "journal": "Astronavigation discussions",
    "doi": "60-135-7342",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Monitored contextually-based internet solution",
    "journal": "Astronavigation discussions",
    "doi": "62-917-8448",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Organic transitional solution",
    "journal": "Biology discoveries",
    "doi": "92-312-6053",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Intuitive static algorithm",
    "journal": "Biology discoveries",
    "doi": "51-868-8516",
    "prioragreement": false,
    "id": 3
  }, {
    "title": "Managed user-facing data-warehouse",
    "journal": "Biology discoveries",
    "doi": "40-863-1380",
    "prioragreement": true,
    "id": 3
  }, {
    "title": "Polarised global hierarchy",
    "journal": "Random letters",
    "doi": "91-039-4418",
    "prioragreement": true,
    "id": 3
  }
]
