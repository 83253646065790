export const unconfirmedInstitutions = [
  {
    "institution": "Economics and Management School, Wuhan University, Wuhan 430072",
    "rorid": "https://ror.org/033vjfk17",
    "rorname": "Wuhan University",
    "id": 47191,
  },
  {
    "institution": "Department of Mathematics, College of Science and Humanities in Al-Kharj, Prince Sattam Bin Abdulaziz University, Al-Kharj 11942",
    "rorid": "https://ror.org/04jt46d36",
    "rorname": "Prince Sattam Bin Abdulaziz University",
    "id": 47191,
  },
  {
    "institution": "School of Management, Shenyang University of Technology, Shenyang 110870",
    "rorid": "https://ror.org/00d7f8730",
    "rorname": "Shenyang University of Technology",
    "id": 47191,
  },
  {
    "institution": "School of Economics and Management, Beijing Jiaotong University, Beijing 100044",
    "rorid": "https://ror.org/01yj56c84",
    "rorname": "Beijing Jiaotong University",
    "id": 47191,
  },
  {
    "institution": "Eye Research Center, Mashhad University of Medical Sciences, Mashhad",
    "rorid": "https://ror.org/04sfka033",
    "rorname": "Mashhad University of Medical Sciences",
    "id": 47191,
  },
  {
    "institution": "School of Computer Science, Qufu Normal University, Rizhao 276826",
    "rorid": "https://ror.org/03ceheh96",
    "rorname": "Qufu Normal University",
    "id": 47191,
  },
  {
    "institution": "Department of Traditional Chinese Medicine, Shanghai Jiao Tong University Affiliated Sixth People2019;s Hospital, Shanghai 200233",
    "rorid": "https://ror.org/0220qvk04",
    "rorname": "Shanghai Jiao Tong University",
    "id": 47191,
  },
  {
    "institution": "College of Transportation, Jilin University, Changchun 130022",
    "rorid": "https://ror.org/00js3aw79",
    "rorname": "Jilin University",
    "id": 47191,
  },
  {
    "institution": "School of Civil Engineering, North China University of Technology, Beijing 100144",
    "rorid": "https://ror.org/01nky7652",
    "rorname": "North China University of Technology",
    "id": 47191,
  },
  {
    "institution": "School of Civil and Resource Engineering, University of Science and Technology Beijing, Beijing 100083",
    "rorid": "https://ror.org/02egmk993",
    "rorname": "University of Science and Technology Beijing",
    "id": 47191,
  },
]