import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { eligibilities } from "./mocks/eligibilities/eligibilities";
import { eligibilityDetails } from "./mocks/eligibilities/eligibilityDetails";
import { historicEligibilities } from "./mocks/eligibilities/historicEligibilities";


export async function getEligibilities() {
    // return await axiosInstance
    // .get(`https://mcrkg7fz9c.execute-api.eu-central-1.amazonaws.com/dev/statistics`)
    // .catch(handleErrors)
    return Promise.resolve({ data: eligibilities });
};

export async function getHistoricEligibilities() {
    // return await axiosInstance
    // .get(`rup/pt/tracker/reports/eligibility/historic`)
    // .catch(handleErrors)
    return Promise.resolve({ data: historicEligibilities });
};

export async function getEligibilityDetails(selectedMessage) {
    // return await axiosInstance
    // .get(`https://mcrkg7fz9c.execute-api.eu-central-1.amazonaws.com/dev/statistics/${selectedMessage}`)
    // .catch(handleErrors)
    return Promise.resolve({ data: eligibilityDetails });
};

