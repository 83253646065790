export const weeklyArticles = [
    {
        "count": 106,
        "week": 4
    },
    {
        "count": 167,
        "week": 5
    },
    {
        "count": 85,
        "week": 6
    },
    {
        "count": 97,
        "week": 7
    },
    {
        "count": 62,
        "week": 8
    }
]