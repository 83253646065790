export const noPidInstitutions = [
  {
    "institution": "Ministry of Agriculture",
    "rorid": "https://ror.org/0220qvk04",
    "rorname": "Shanghai Jiao Tong University",
    "id": 1,
  },
  {
    "institution": "College of Transportation",
    "rorid": "https://ror.org/00js3aw79",
    "rorname": "Jilin University",
    "id": 2,
  },
  {
    "institution": "School of Civil Engineering",
    "rorid": "https://ror.org/01nky7652",
    "rorname": "North China University of Technology",
    "id": 3,
  },
  {
    "institution": "School of Civil and Resource Engineering",
    "rorid": "https://ror.org/02egmk993",
    "rorname": "University of Science and Technology Beijing",
    "id": 4,
  },
  {
    "institution": "Economics and Management School",
    "rorid": "https://ror.org/033vjfk17",
    "rorname": "Wuhan University",
    "id": 5,
  },
  {
    "institution": "Department of Mathematics",
    "rorid": "https://ror.org/04jt46d36",
    "rorname": "Prince Sattam Bin Abdulaziz University",
    "id": 6,
  },
  {
    "institution": "School of Management",
    "rorid": "https://ror.org/00d7f8730",
    "rorname": "Shenyang University of Technology",
    "id": 7,
  },
  {
    "institution": "School of Economics and Management",
    "rorid": "https://ror.org/01yj56c84",
    "rorname": "Beijing Jiaotong University",
    "id": 8,
  },
]