export const institutionMessages = [
  {
    "id": 274,
    "date": "2022-09-28",
    "type": "p1",
    "send_to": "World Maritime University",
    "delivered_to": "World Maritime University",
    "manuscript_id": "5298706172",
    "doi": "25-645-8238",
  },
  {
    "id": 265,
    "date": "2022-09-18",
    "type": "e1",
    "send_to": "Göteborg University",
    "delivered_to": "Göteborg University",
    "manuscript_id": "9786748697",
    "doi": "80-623-3424",
  },
  {
    "id": 242,
    "date": "2022-09-14",
    "type": "p1",
    "send_to": "Göteborg University",
    "delivered_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "manuscript_id": "9786748689",
    "doi": "80-623-2865",
  },
  {
    "id": 218,
    "date": "2022-08-12",
    "type": "p1",
    "send_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "delivered_to": "-",
    "manuscript_id": "6702268947",
    "doi": "60-135-7342",
  },
  {
    "id": 204,
    "date": "2022-07-27",
    "type": "e1",
    "send_to": "Preston Institute of Management Sciences and Technology (PIMSAT)",
    "delivered_to": "-",
    "manuscript_id": "0089466697",
    "doi": "62-917-8521",
  },
  {
    "id": 189,
    "date": "2022-07-21",
    "type": "p1",
    "send_to": "Université de Kolwezi",
    "delivered_to": "-",
    "manuscript_id": "0089466691",
    "doi": "62-917-8448",
  },
]
