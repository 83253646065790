import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";
import { weeklyTransactions } from "./mocks/weeklyTransactions";
import { weeklyArticles } from "./mocks/weeklyArticles";
import { lastMonthTransactions } from "./mocks/lastMonthTransactions";
import { lastMonthArticles } from "./mocks/lastMonthArticles";
import { weeklyInstitutions } from "./mocks/weeklyInstitutions";
import { funderArticles } from "./mocks/funderArticles";
import { weeklyAgreements } from "./mocks/weeklyAgreements";
import { institutionTransactions } from "./mocks/institutionTransactions";
import { lastYearArticles } from "./mocks/lastYearArticles";
import { institutionDeals } from "./mocks/institutionDeals";


export async function getWeeklyTransactions() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/weekly/transactions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: weeklyTransactions })
};

export async function getLastMonthTransactions() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/lastmonth/transactions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: lastMonthTransactions })
};

export async function getTransactionsPerInstitution() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/institutions/transactions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionTransactions })
};

export async function getWeeklyArticles() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/weekly/articles`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: weeklyArticles })
};

export async function getLastMonthArticles() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/lastmonth/articles`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: lastMonthArticles })
};

export async function getArticlesWithoutIdentifiers() {
    return await axiosInstance
        .get(`trs/tracker/reports/articles/withoutidentifiers`)
        .catch(handleErrors)
};

export async function getArticlesInProduction() {
    return await axiosInstance
        .get(`trs/tracker/reports/articles/production`)
        .catch(handleErrors)
};

export async function getArticlesPerInstitution() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/institutions/articles`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionDeals })
};

export async function getArticlesPerFunder() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/institutions/articles`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: funderArticles })
};


export async function getInstitutions() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/weekly/institutions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: weeklyInstitutions })
};

export async function getAgreements() {
    // return await axiosInstance
    //     .get(`trs/tracker/reports/weekly/agreements`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: weeklyAgreements })
};

export async function getLastYearArticles() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/lastyear/articles`)
    // .catch(handleErrors)
    return Promise.resolve({ data: lastYearArticles })
};
