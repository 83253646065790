export const weeklyTransactions = [
  {
    "count": 24,
    "week": 4
  },
  {
    "count": 20,
    "week": 5
  },
  {
    "count": 28,
    "week": 6
  },
  {
    "count": 26,
    "week": 7
  },
  {
    "count": 22,
    "week": 8
  }
]