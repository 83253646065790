export const weeklyAgreements = [
    {
        "month": "2022-1",
        "prioragreement": 5,
        "noprioragreement": 16
    },
    {
        "month": "2022-2",
        "prioragreement": 18,
        "noprioragreement": 27
    },
    {
        "month": "2022-3",
        "prioragreement": 15,
        "noprioragreement": 21
    },
    {
        "month": "2022-4",
        "prioragreement": 8,
        "noprioragreement": 17
    },
    {
        "month": "2022-5",
        "prioragreement": 23,
        "noprioragreement": 33
    }
]