export const NoPidArticles = [
    {
        "id": 47191,
        "manuscript_id": null,
        "title": "Mild and Efficient Cu-Catalyzed Synthesis of Trisubstituted Pyrroles",
        "authors": [
            {
                "lastname": "Ming-Hua Hsu",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "TW",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "National Changhua University of Education",
            },
            {
                "lastname": "Ming-Hua Hsu",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "TW",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "National Changhua University of Education",
                "institutions": [
                    {
                        "name": "National Changhua University of Education",
                        "ror_id": "https://ror.org/005gkfa10"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1331-7346",
        "state": "import"
    },
    {
        "id": 47192,
        "manuscript_id": null,
        "title": "Recent Advances in Transition Metal Catalyzed C-H Functionalization Reactions Involving Aza/Oxabicyclic Alkenes",
        "authors": [
            {
                "lastname": "Masilamani Jeganmohan",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Indian Institute of Technology Hyderabad",
            },
            {
                "lastname": "Masilamani Jeganmohan",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Indian Institute of Technology Hyderabad",
                "institutions": [
                    {
                        "name": "Indian Institute of Technology Hyderabad",
                        "ror_id": "https://ror.org/01j4v3x97"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1528-1711",
        "state": "import"
    },
    {
        "id": 47193,
        "manuscript_id": null,
        "title": "Copper and N-heterocyclic carbene (NHC) catalyzed oxidative amidation of aldehydes with amines",
        "authors": [
            {
                "lastname": "Anudeep Kumar Narula",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Guru Gobind Singh Indraprastha University",
            },
            {
                "lastname": "Anudeep Kumar Narula",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Guru Gobind Singh Indraprastha University",
                "institutions": [
                    {
                        "name": "Guru Gobind Singh Indraprastha University",
                        "ror_id": "https://ror.org/034q1za58"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1343-5203",
        "state": "import"
    },
    {
        "id": 47194,
        "manuscript_id": null,
        "title": "Latest Applications of the Diels-Alder Reaction in the Synthesis of Natural Products (2017-2020)",
        "authors": [
            {
                "lastname": "Markus Kalesse",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "DE",
                "orcid": "0000-0003-4858-3957",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "University of Hannover",
            },
            {
                "lastname": "Markus Kalesse",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "DE",
                "orcid": "0000-0003-4858-3957",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "University of Hannover",
                "institutions": [
                    {
                        "name": "University of Hannover",
                        "ror_id": "https://ror.org/0304hq317"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1532-4763",
        "state": "import"
    },
    {
        "id": 47195,
        "manuscript_id": null,
        "title": "Synthesis of a Glycosylphosphatidylinositol (GPI) fragment as a potential substrate for mannoprotein transglycosidases",
        "authors": [
            {
                "lastname": "Tyson Belz",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "US",
                "orcid": "0000-0002-1073-3588",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Scripps Research Institute",
            },
            {
                "lastname": "Tyson Belz",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "US",
                "orcid": "0000-0002-1073-3588",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "Scripps Research Institute",
                "institutions": [
                    {
                        "name": "Scripps Research Institute",
                        "ror_id": "https://ror.org/02dxx6824"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1523-1638",
        "state": "import"
    },

    {
        "id": 47294,
        "manuscript_id": null,
        "title": "Visible Light as the Key for the Formation of Carbon-Sulfur Bonds in Sulfones, Thioethers and Sulfonamides: an Update",
        "authors": [
            {
                "lastname": "Annamaria Deagostino",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IT",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "University of Turin",
                "institutions": [
                    {
                        "name": "University of Turin",
                        "ror_id": "https://ror.org/048tbm396"
                    }
                ]
            },
            {
                "lastname": "Annamaria Deagostino",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IT",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "University of Turin",
                "institutions": [
                    {
                        "name": "University of Turin",
                        "ror_id": "https://ror.org/048tbm396"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1509-5541",
        "state": "import"
    },
    {
        "id": 47282,
        "manuscript_id": null,
        "title": "A Practical and Efficient Conversion of Luteolin into Luteoloside",
        "authors": [
            {
                "lastname": "Benedetta Bassetti",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IT",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "University of Camerino",
            },
            {
                "lastname": "Benedetta Bassetti",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IT",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "University of Camerino",
            },
            {
                "lastname": "Alessandro Palmieri",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "IT",
                "orcid": "0000-0001-6599-3937",
                "creditroles": [],
                "order": 1,
                "iscorresponding": true,
                "affiliation": "University of Camerino",
                "institutions": [
                    {
                        "name": "University of Camerino",
                        "ror_id": "https://ror.org/0005w8d69"
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1531-2385",
        "state": "import"
    },
    {
        "id": 47283,
        "manuscript_id": null,
        "title": "Selectfluor-Mediated Tandem Cyclization of Enaminones for the Synthesis of 3-Fluorochromones",
        "authors": [
            {
                "lastname": "Venu Kandula",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Venu Kandula",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Pradeep Kumar Thota",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Poosa Mallesham",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "K. Raghavulu",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Satyanarayana Yennam",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Manoranjan Behera",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            }
        ],
        "doi": "10.1055/s-0039-1691489",
        "state": "import"
    },
    {
        "id": 47284,
        "manuscript_id": null,
        "title": "Tandem Schiff-Base Formation/Heterocyclization: An Approach to the Synthesis of Fused Pyrazolo–Pyrimidine/Isoxazolo-Pyrimidine Hybrids",
        "authors": [
            {
                "lastname": "M. Sambaiah",
                "firstname": "",
                "initials": "",
                "email": "manoranjan.behera@gvkbio.com",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "GVK (India)",
                "institutions": [
                    {
                        "name": "GVK (India)",
                        "ror_id": "https://ror.org/00eqbn006"
                    }
                ]
            },
            {
                "lastname": "M. Sambaiah",
                "firstname": "",
                "initials": "",
                "email": "manoranjan.behera@gvkbio.com",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "GVK (India)",
                "institutions": [
                    {
                        "name": "GVK (India)",
                        "ror_id": "https://ror.org/00eqbn006"
                    }
                ]
            },
            {
                "lastname": "Poosa Mallesham",
                "firstname": "",
                "initials": "",
                "email": "manoranjan.behera@gvkbio.com",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "GVK (India)",
                "institutions": [
                    {
                        "name": "GVK (India)",
                        "ror_id": "https://ror.org/00eqbn006"
                    }
                ]
            },
            {
                "lastname": "Satyanarayana Yennam",
                "firstname": "",
                "initials": "",
                "email": "manoranjan.behera@gvkbio.com",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "GVK (India)",
                "institutions": [
                    {
                        "name": "GVK (India)",
                        "ror_id": "https://ror.org/00eqbn006"
                    }
                ]
            },
            {
                "lastname": "Manoranjan Behera",
                "firstname": "",
                "initials": "",
                "email": "manoranjan.behera@gvkbio.com",
                "country": "IN",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "GVK (India)",
                "institutions": [
                    {
                        "name": "GVK (India)",
                        "ror_id": "https://ror.org/00eqbn006"
                    }
                ]
            }
        ],
        "doi": "10.1055/s-0037-1612081",
        "state": "import"
    },
    {
        "id": 47285,
        "manuscript_id": null,
        "title": "Oxygen-tethered 1,6-enynes and [4.1.0]-Bicyclic ether skeletons as hedonic materials for the fragrance industry",
        "authors": [
            {
                "lastname": "Christophe MARIN",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            },
            {
                "lastname": "Christophe MARIN",
                "firstname": "",
                "initials": "",
                "email": " ",
                "country": "",
                "orcid": " ",
                "creditroles": [],
                "order": 1,
                "iscorresponding": false,
                "affiliation": "",
                "institutions": [
                    {
                        "name": "",
                        "ror_id": ""
                    }
                ]
            }
        ],
        "doi": "10.1055/a-1526-7917",
        "state": "import"
    }
]
