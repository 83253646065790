import { dealsDetails } from "./mocks/dealsDetails";
import { institutionMessages } from "./mocks/institutionMessages";
import { institutionsPercentage } from "./mocks/institutionsPercentage";
import { institutionsRorPercentage } from "./mocks/institutionsRorPercentage";
import { institutionJournals } from "./mocks/institutionJournals";
import { institutionArticlesPerMonth } from "./mocks/institutionArticlesPerMonth";
import { enrichmentTypesPercentage } from "./mocks/enrichmentTypesPercentage";
import { unconfirmedInstitutions } from "./mocks/unconfirmedInstitutions";
import { noPidInstitutions } from "./mocks/noPidInstitutions";
import { noRinggoldInstitutions } from "./mocks/noRinggoldInstitutions";
import { institutionsP1Messages } from "./mocks/institutionsP1Messages";
import { institutionDataPerMonth } from "./mocks/institutionDataPerMonth";
import { articles } from "./mocks/articles";
import { journals } from "./mocks/journals";


export async function getInstitutionDetails(id) {
    // const res = await fetch(`https://api.oaswitchboard.org/hindawi/tracker/reports/institution/articles?query=${id}`)
    // return res.json()
    //     .catch(handleErrors)
    return Promise.resolve({ data: dealsDetails });
};


export async function getInstitutionMessages(id) {
    // const res = await fetch(`https://api.oaswitchboard.org/hindawi/tracker/reports/institution/messages?query=${id}`)
    // return res.json()
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionMessages });
};

export async function getInstitutionsPercentage() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/identifiers`)
    // .catch(handleErrors)
    return Promise.resolve({ data: institutionsPercentage });
};

export async function getP1Messages() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/messages`)
    // .catch(handleErrors)
    return Promise.resolve({ data: institutionsP1Messages });
};

export async function getEnrichmentTypesPercentage() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/identifiers`)
    // .catch(handleErrors)
    return Promise.resolve({ data: enrichmentTypesPercentage });
};

export async function getRorInstitutionsPercentage() {
    // return await axiosInstance
    // .get(`hindawi/tracker/reports/articles/identifiers`)
    // .catch(handleErrors)
    return Promise.resolve({ data: institutionsRorPercentage });
};

export async function getInstitutionArticlesPerMonth(id) {
    // return await axiosInstance
    //     .get(`hindawi/tracker/reports/lastyear/articles?ror=${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionArticlesPerMonth });
};

export async function getInstitutionDataPerMonth(id) {
    // return await axiosInstance
    //     .get(`hindawi/tracker/reports/lastyear/articles?ror=${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionDataPerMonth });
};

export async function getInstitutionJournals(id) {
    // return await axiosInstance
    //     .get(`hindawi/tracker/reports/articles/journals?ror=${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: institutionJournals });
};

export async function getUnconfirmedInstitutions() {
    // return await axiosInstance
    //     .get(`hindawi/tracker/reports/articles/journals?ror=${id}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: unconfirmedInstitutions });
};

export async function confirmInstitution(id) {
    // return await axiosInstance
    //     .post(`hindawi/tracker/institution/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve();
};

export async function getNoPidInstitutions() {
    // return await axiosInstance
    //     .get(`hindawi/tracker/nopidinstitutions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: noPidInstitutions });
};

export async function saveInstitutionRor(id, ror) {
    // return await axiosInstance
    //     .post(`hindawi/tracker/institution/${id}`)
    //     .catch(handleErrors)
    return Promise.resolve();
};

export async function getNoRinggoldInstitutions() {
    // return await axiosInstance
    //     .get(`hindawi/tracker/nopidinstitutions`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: noRinggoldInstitutions });
};


export async function getInstitutionArticles(rorId, type) {
    // return await axiosInstance
    //     .get(`tracker/reports/institution/articles?ror=${rorId}&selection=${type}`)
    //     .catch(handleErrors)
    if (type === 'production') {
        return Promise.resolve({ data: articles.articles })
    }
    else {
        return Promise.resolve({ data: journals })
    }
};

export async function getFunderArticles(rorId, type) {
    // return await axiosInstance
    //     .get(`tracker/reports/funder/articles?ror=${rorId}&selection=${type}`)
    //     .catch(handleErrors)
    if (type === 'production') {
        return Promise.resolve({ data: articles.articles })
    }
    else {
        return Promise.resolve({ data: journals })
    }
}
