import { MDBBtn } from 'mdbreact'

const customBtnStyles = {
    display: 'block',
    padding: 0,
    margin: 0,
    color: 'blue',
    textTransform: 'lowercase'
};

const manuscriptStyles = {
    marginBottom: '10px'
};

const ArticleIDsCellContent = ({ row }) => {
    const handleButtonClick = (e) => {
        e.stopPropagation();
        window.open(row.original?.doi, '_blank');
    };

    return (
        <>
            {row?.original?.doi &&
                <MDBBtn style={customBtnStyles} color='link' rippleColor='light' onClick={handleButtonClick}>
                    {row.original.doi}
                </MDBBtn>}
            {row?.original?.manuscript && <span style={manuscriptStyles}>{row.original.manuscript}</span>}
        </>
    );
}

export const messagesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
        sortable: true
    },
    {
        Header: 'created',
        Cell: ({ row }) =>
            <span>{row.original.date}</span>,
        sortable: true
    },
    {
        Header: 'type',
        accessor: 'type',
        sortable: true,
    },
    {
        Header: 'sent to',
        accessor: 'send_to',
        sortable: true
    },
    {
        Header: 'article IDs',
        Cell: ArticleIDsCellContent,
        sortable: true
    },
    {
        Header: 'state',
        accessor: 'state',
        sortable: true
    },
]
