export const funderArticles = [
    {
        "count": 7,
        "institution": "Commission for Development Research at the OeAD-GmbH (KEF)",
        "rorid": "https://ror.org/033vjfk17"
    },
    {
        "count": 7,
        "institution": "International Development Research Center",
        "rorid": "https://ror.org/04jt46d36"
    },
    {
        "count": 5,
        "institution": "Chinese Academy of Sciences (CAS)",
        "rorid": "https://ror.org/00d7f8730"
    },
    {
        "count": 4,
        "institution": "European Cooperation in Science and Technology (COST)",
        "rorid": "https://ror.org/04sfka033"
    },
    {
        "count": 4,
        "institution": "European Research Council",
        "rorid": "https://ror.org/03ceheh96"
    },
    {
        "count": 3,
        "institution": "Humanities in the European Research Area",
        "rorid": "https://ror.org/0220qvk04"
    },
    {
        "count": 3,
        "institution": "Academy of Finland",
        "rorid": "https://ror.org/00js3aw79"
    },
    {
        "count": 3,
        "institution": "Deutsche Forschungsgemeinschaft (DFG)",
        "rorid": "https://ror.org/02egmk993"
    },
    {
        "count": 3,
        "institution": "Federal Ministry of Education and Research (Bundesministerin für Bildung und Forschung - BMBF)",
        "rorid": "https://ror.org/037xrmj59"
    },
    {
        "count": 3,
        "institution": "Hungarian Academy of Sciences (Magyar Tudományos Akadémia, MTA)",
        "rorid": "https://ror.org/019c89h28"
    },
    {
        "count": 3,
        "institution": "Bill and Melinda Gates Foundation",
        "rorid": "https://ror.org/007kf5222"
    },
    {
        "count": 3,
        "institution": "CGIAR Consortium of International Agricultural Research Centers",
        "rorid": "https://ror.org/02ma4wv74"
    },
    {
        "count": 3,
        "institution": "Nordic Council of Ministers (NORDEN)/NordForsk",
        "rorid": "https://ror.org/03bs4te22"
    },
    {
        "count": 3,
        "institution": "World Health Organization",
        "rorid": "https://ror.org/00445hv47"
    },
    {
        "count": 3,
        "institution": "Environmental Protection Agency",
        "rorid": "https://ror.org/05wdbfp45"
    },
    {
        "count": 3,
        "institution": "Science Foundation Ireland",
        "rorid": "https://ror.org/0207yh398"
    },
    {
        "count": 3,
        "institution": "Research Council of Lithuania (Lietuvos mokslo taryba)",
        "rorid": "https://ror.org/022e9e065"
    },
    {
        "count": 3,
        "institution": "Fundacao para a Ciencia e a Tecnologia",
        "rorid": "https://ror.org/05s2ecw65"
    },
    {
        "count": 2,
        "institution": "Ministry of Education, Science and Sport",
        "rorid": "https://ror.org/01gbfax37"
    },
    {
        "count": 2,
        "institution": "Swiss National Science Foundation",
        "rorid": "https://ror.org/00j08hk87"
    },
    {
        "count": 2,
        "institution": "The Netherlands Organisation for Scientific Research (NWO)",
        "rorid": "https://ror.org/01d0bkz51"
    },
    {
        "count": 2,
        "institution": "Department for International Development (See also the Implementation Guide)",
        "rorid": "https://ror.org/02wmsc916"
    },
    {
        "count": 2,
        "institution": "UK Research and Innovation",
        "rorid": "https://ror.org/05d2yfz11"
    }
]