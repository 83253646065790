import { messages } from "./mocks/messages";
import { messagesPerMonth } from "./mocks/messagesPerMonth";
import { messagesTypes } from "./mocks/messagesTypes";


export async function getMessages(filterValue, ror = '') {
    const filteredMessages = filterValue ? messages.filter(x => x.type === filterValue) : messages;
    const filteredMessagesByRor = ror ? filteredMessages.filter(x => x?.rorId === ror) : filteredMessages
    return Promise.resolve({ data: { messages: filteredMessagesByRor, total: filteredMessagesByRor.length } });
};

export async function getMessagesPerMonth() {
    // return await axiosInstance
    //     .get(`trs/tracker/article/${selectedMessage}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: messagesPerMonth });
};

export async function getMessagesTypes() {
    // return await axiosInstance
    //     .get(`trs/tracker/article/${selectedMessage}`)
    //     .catch(handleErrors)
    return Promise.resolve({ data: messagesTypes });
};
